// Fix for buggy prismic previews
// https://github.com/angeloashmore/gatsby-source-prismic/issues/139#issuecomment-545204029

import { mergePrismicPreviewData } from 'gatsby-source-prismic'
// import traverse from 'traverse'
// import { get }from 'lodash-es'

const IS_BROWSER = typeof window !== 'undefined'

// function makeNullsUndefined(node) {
//  if (node === null) {
//    this.update(undefined)
//  }
// }

const mergePreviewData = staticData => {
  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__

  // return previewData || staticData

  /* return mergePrismicPreviewData({
    staticData: traverse(staticData).map(makeNullsUndefined),
    previewData: previewData
      ? traverse(previewData).map(makeNullsUndefined)
      : null,
  }) */

  return mergePrismicPreviewData({
    staticData,
    previewData,
  })
}

export default mergePreviewData
